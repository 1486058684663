import React from 'react';
import {Container, Row} from 'react-bootstrap';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/section';
import SectionHero from '../../components/section-hero';
import SectionSubscribe from '../../components/section-subscribe';
import StudyCard from '../../components/card-study';

const studiesPage = () => (
  <Layout className="studies">
    <SEO title="Studies" />
    <SectionHero className="studies__hero" fileName="studies-header.png">
      <Container className="studies__hero__container">
        <h1>Studies</h1>
      </Container>
    </SectionHero>
    <Section className="studies__section">
      <Row>
        <h2>Sources of Our Data</h2>
        <p>
          The vast size and diversity of data shared with the world through St. Jude Cloud would not
          be possible without months or years of careful experimental design, data collection, and
          quality checking from individuals working at or in collaboration with St. Jude
          Children&apos;s Research Hospital. The dedication of the teams that carry out these
          investigations is, in large part, what makes St. Jude Cloud possible today. Learn about
          the research programs that contribute their data to St. Jude Cloud and subsequently where
          to find those resources in the pages below.
        </p>
      </Row>
    </Section>
    <Section gray>
      <Row className="studies__list">
        <StudyCard
          title="Pediatric Cancer Genome Project"
          url="/studies/pediatric-cancer-genome-project"
        />
        <StudyCard title="Clinical Genomics" url="/studies/clinical-genomics" />
        <StudyCard title="NetAD" url="/studies/netad" />
        <StudyCard
          title="Sickle Cell Genome Project"
          url="https://sickle-cell.stjude.cloud/"
          external
        />
        <StudyCard title="St. Jude LIFE" url="https://sjlife.stjude.org/" external />
        <StudyCard
          title="Childhood Cancer Survivor Study"
          url="https://ccss.stjude.org/"
          external
        />
      </Row>
    </Section>
    <SectionSubscribe />
  </Layout>
);

export default studiesPage;
